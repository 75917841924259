<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CalendarView v-if="triedLoad === true" :itemFilter="false" :inventoryFilter="false" :items="items" :paymentStatusFilter="true" :experienceFilter="true" :shouldBeListVisible="true"/>
      </transition>
    </CCol>
    <CSpinner
      style="display: block; position: fixed; top: 50%; left: 50%"
      v-if="items.length == 0 && triedLoad === false"
      color="primary"
    />
  </CRow>
</template>

<script>
import axios from "axios";
import router from '@/router/index'
import CalendarView from '@/views/calendarview/CalendarView'
export default {
  name: "InventoryCalendar",
  components: {
    CalendarView
  },
  data: () => { return{
        bookings:[],
        items:[],
        bookingEvents:[],
        triedLoad : false,
        inventoryId:"00000000-0000-0000-0000-000000000000",
        inventoryItemId:"00000000-0000-0000-0000-000000000000",
    };
  },
  computed: {},
  methods: {
    getDetails(id) {
      const link = `/bookings/details/${id.toString()}`;
      this.$router.push({ path: link });
    },
    getFiltered()
    {
      let self = this;
      axios
        .get(

          this.$apiAdress +
            "/v1/Booking?experienceId=" +
           "00000000-0000-0000-0000-000000000000" +
            "&paymentStatus=" +
            ""+
            "&inventoryId=" +
            this.inventoryId.toString()+
            "&inventoryItemId=" +
            this.inventoryItemId.toString()
        )
        .then(function (response) {

          self.items = [];
          self.bookings = response.data;
          self.bookings.map(function (value, key) {
            value.bookingNumber = value.bookingNumber;
            value.fullName =
              value.customer.firstName + " " + value.customer.lastName;
            value.createDate = new Date(value.createDate)
              .toISOString()
              .split("T")[0];

            value.bookingDates =
              new Date(value.startDate).toISOString().split("T")[0] +
              " - " +
              new Date(value.endDate).toISOString().split("T")[0];

            value.createDate = new Date(value.createDate)
              .toISOString()
              .split("T")[0];

            self.items.push(value);
          } );
          self.triedLoad=true;
        })
        .catch(function (error) {
        });
    }

  },
  mounted: function () {
    this.inventoryItemId = this.$route.query.inventoryItemId;
    this.inventoryId  = this.$route.query.inventoryId;
    this.getFiltered();
  },
};
</script>
